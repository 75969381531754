import React from "react";
import IconButton from "Components/UI/IconButton";
import ImageField from "Components/UI/Field/ImageField";
import LoadImage from "Components/Layout/LoadImage";
import Spinner from "Components/Feedback/Spinner";

class WysiwygImageBlock extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Controls = true;
        this.state = {
            align: "fill",
            error: false,
            loading: false,
            src: ""
        }
    }

    componentDidMount()
    {
        const {block, contentState} = this.props;
        const {align, src} = contentState.getEntity(block.getEntityAt(0)).getData();
        this.setState({align, loading: true, src});
    }

    componentDidUpdate()
    {
        const {block, contentState} = this.props;
        const {align: currentAlign, src: currentSrc} = this.state;
        const {align, src} = contentState.getEntity(block.getEntityAt(0)).getData();
        if (align !== currentAlign || src !== currentSrc)
        {
            this.setState({align, error: false, loading: src !== currentSrc, src});
        }
    }

    OnAlign = (e, align) =>
    {
        const {block, blockProps = {}, contentState} = this.props;
        const {onAlign = () => {}} = blockProps;
        onAlign(block, contentState, align);
        this.setState({align});
    }

    OnChangeImage = (e, image) =>
    {
        const {block, blockProps = {}, contentState} = this.props;
        const {onChange = () => {}} = blockProps;
        const src = Array.isArray(image) ? image[0] : false
        onChange(block, contentState, src);
        this.setState({error: false, loading: true, src});
    }

    OnDelete = () =>
    {
        const {block, blockProps = {}, contentState} = this.props;
        const {onDelete = () => {}} = blockProps;
        onDelete(block, contentState);
    }

    OnError = () =>
    {
        this.setState({error: true, loading: false});
    }

    OnLoad = () =>
    {
        this.setState({loading: false});
    }

    render()
    {
        const {align, loading, src} = this.state;
        const CA = ["WysiwygImageBlock"];
        switch (align)
        {
            case "left":
                CA.push("AlignLeft");
                break;
            case "right":
                CA.push("AlignRight");
                break;
            default:
                CA.push("AlignFill");
        }
        if (loading)
        {
            CA.push("Loading");
        }
        return (
            <div className={CA.join(" ")}>
                {loading ? <Spinner className="WysiwygImageBlockSpinner"/> : ""}
                <LoadImage
                    autoAdjust={true}
                    className="WysiwygImageBlockImage"
                    onError={this.OnError}
                    onLoad={this.OnLoad}
                    src={src}
                />
                {this.Controls ? <div className="WysiwygImageBlockToolbar">
                    <div className="WysiwygImageBlockToolbarLeft">
                        <IconButton
                            active={align === "left"}
                            className="WysiwygImageBlockToolbarButton"
                            feather="AlignLeft"
                            id="left"
                            onClick={this.OnAlign}
                        />
                        <IconButton
                            active={align !== "left" && align !== "right"}
                            className="WysiwygImageBlockToolbarButton"
                            feather="AlignJustify"
                            id="fill"
                            onClick={this.OnAlign}
                        />
                        <IconButton
                            active={align === "right"}
                            className="WysiwygImageBlockToolbarButton"
                            feather="AlignRight"
                            id="right"
                            onClick={this.OnAlign}
                        />
                    </div>
                    <div className="WysiwygImageBlockToolbarRight">
                        <ImageField
                            className="WysiwygImageBlockToolbarButton"
                            onChange={this.OnChangeImage}
                            returnImage={true}
                            minimal={true}
                        />
                        <IconButton
                            className="WysiwygImageBlockToolbarButton"
                            feather="X"
                            onClick={this.OnDelete}
                        />
                    </div>
                </div> : ""}
            </div>
        );
    }
}

export default WysiwygImageBlock;